document.addEventListener("DOMContentLoaded", function() {
    const sessionToken = sessionStorage.getItem('token');

    // If no session token is found, show the login popup
    if (!sessionToken && window.location.pathname.includes('/games/')) {
        showLoginPopup();
    }

    // Extract language from URL
    const url = new URL(window.location.href);
    const path = url.pathname.split('/');
    const availableLanguages = {
        'es': 'es_ES',
        'de': 'de_DE',
        'it': 'it_IT',
        'pt': 'pt_PT',
        'fr': 'fr_FR'
    };
    let language = 'en_US'; // default language
    if (availableLanguages.hasOwnProperty(path[1])) {
        language = availableLanguages[path[1]];
    }

    // Function to show the login popup
    function showLoginPopup() {
        popupHandler.closePopups();
        popupHandler.loginPopup.style.display = "block";
        popupHandler.transparentBackdrop.style.display = "block";
    }

    // Check if the api_slug is available
    if (ajax_object.api_slug) {

        // Setup control buttons
        setupControlButtons();

        // Automatically start the game with the api_slug
        startGame(ajax_object.api_slug, language);
    } else {
        //console.error("API slug is not available.");
    }

    // Listen for login event to start the game
    document.addEventListener('userLoggedIn', function() {
        const newSessionToken = sessionStorage.getItem('token');
        if (newSessionToken) {
            startGame(ajax_object.api_slug, language);
        }
    });

    // Fallback to check if token is set and start the game
    if (sessionStorage.getItem('token')) {
        const event = new Event('userLoggedIn');
        document.dispatchEvent(event);
    }
});

function startGame(api_slug, language) {
    var token = sessionStorage.getItem('token'); // Retrieve the token from sessionStorage

    if (!token || !api_slug) {
        return;
    }

    var data = {
        'action': 'start_game',
        'api_slug': api_slug,
        'security': ajax_object.nonce,
        'token': token, // Include the token in the AJAX request
        'language': language // Include the language in the AJAX request
    };

    //console.log("Starting game with api_slug:", api_slug, "token:", token, "and language:", language);

    jQuery.post(ajax_object.ajaxurl, data, function(response) {
        //console.log("Response from server:", response);
        if (response.success) {
            if (response.data && response.data.game_uri) {
                var gameIframe = document.getElementById("gameIframe");
                if (gameIframe) {
                    gameIframe.src = response.data.game_uri;
                    var gamePlaceholder = document.getElementById("gamePlaceholder");
                    if (gamePlaceholder) {
                        gamePlaceholder.style.display = "none";
                    }
                }
            } else {
                //console.error("Game URI is not available.");
            }
        } else {
            //console.error("Failed to start game:", response.data);
        }
    }).fail(function(xhr, status, error) {
        //console.error("AJAX error: ", status, error);
    });
}

function setupControlButtons() {
    var closeGameButton = document.getElementById("closeGameButton");
    var fullscreenButton = document.getElementById("fullscreenButton");
    var gameIframe = document.getElementById("gameIframe");

    if (closeGameButton) {
        closeGameButton.addEventListener("click", function() {
            const pathSegments = window.location.pathname.split('/');
            const languageTag = pathSegments[1];
            const validLanguages = ['es', 'de', 'pt', 'fr', 'it'];
        
            if (window.location.pathname.includes('/games/')) {
                if (validLanguages.includes(languageTag)) {
                    window.location.href = `/${languageTag}/`;
                } else {
                    window.location.href = '/';
                }
            } 
        });
    }

    if (fullscreenButton && gameIframe) {
        fullscreenButton.addEventListener("click", function() {
            if (gameIframe.requestFullscreen) {
                gameIframe.requestFullscreen();
            } else if (gameIframe.mozRequestFullScreen) { /* Firefox */
                gameIframe.mozRequestFullScreen();
            } else if (gameIframe.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                gameIframe.webkitRequestFullscreen();
            } else if (gameIframe.msRequestFullscreen) { /* IE/Edge */
                gameIframe.msRequestFullscreen();
            }
        });
    }
}

// Trigger 'userLoggedIn' event when the user logs in successfully
document.addEventListener('tokenSet', () => {
    const event = new Event('userLoggedIn');
    document.dispatchEvent(event);
});
